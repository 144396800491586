import React, { useState, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ListItemIcon,
  CircularProgress,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CircleIcon from "@mui/icons-material/Circle";
import Slide from "@mui/material/Slide";
import Backicon from "../../assets/images/back.svg";
import SearchIcon from "@mui/icons-material/Search";
import TokenSelectIcon from "../../assets/images/token-sel-icon.svg";
import arbitrumLogo from "../../assets/images/chains/arbitrum-logo.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// const filteredOptions = [
//   {
//     id: 1,
//     tokenName: "USDT",
//     tokenSymbol: "AvL",
//     tokenIcon: "avalanche.svg",
//   },
//   {
//     id: 2,
//     tokenName: "USDT",
//     tokenSymbol: "AvL",
//     tokenIcon: "avalanche.svg",
//   },
//   {
//     id: 3,
//     tokenName: "USDT",
//     tokenSymbol: "AvL",
//     tokenIcon: "avalanche.svg",
//   },
// ];

const SelectChain = ({
  SupportedBlockchains,
  setSelectedFromChainForDropdown,
  setSelectedToChainForDropdown,
  selectedFromChainForDropdown,
  selectedToChainForDropdown,
  isItFromToken,
}) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const handleOpenDialog = () => {
    setOpen(true);
    setSearchValue("");
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSelectedChain = (option) => {
    if (isItFromToken) {
      setSelectedFromChainForDropdown(option);
    } else setSelectedToChainForDropdown(option);
    setOpen(false);
  };
  console.log(
    "chainlogo",
    isItFromToken,
    selectedFromChainForDropdown?.chainName?.logo,
    selectedToChainForDropdown?.chainName?.logo
  );

  return (
    <div className="select-dialog-custom">
      <div>
        <Button
          className="select-input-style"
          onClick={handleOpenDialog}
          variant="outlined"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="icon-container">
            <img
              alt="token-icon"
              style={{
                marginRight: "8px",
                width: "28px",
                height: "28px",
              }}
              //   src="../../assets/avalanche.svg"
              src={
                `../../../Assets/chains/` +
                `${
                  isItFromToken
                    ? selectedFromChainForDropdown?.logo
                    : selectedToChainForDropdown?.logo
                }`
              }
            />
            {/* <CircleIcon
                                style={{ marginRight: "8px", color: "rgb(90, 98, 112)" }}
                            /> */}
            {isItFromToken
              ? selectedFromChainForDropdown?.chainName
              : selectedToChainForDropdown?.chainName}
          </div>
          <ArrowDropDownIcon />
        </Button>

        <Dialog
          className="dialog-style"
          open={open}
          onClose={handleCloseDialog}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            <div className="header-select d-flex">
              <img
                onClick={handleCloseDialog}
                src={Backicon}
                alt="SVG Icon"
                style={{ width: "24px", height: "24px", marginRight: "8px" }}
              />
              <h2> Select Chain</h2>
            </div>
          </DialogTitle>

          <DialogContent className="token-select-dialog">
            <TextField
              placeholder="Search by name or symbol"
              inputProps={{
                autoComplete: "off",
              }}
              value={searchValue}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <ListItemIcon className="search-placeholder">
                    <SearchIcon className="text-white" />
                  </ListItemIcon>
                ),
              }}
              sx={{
                // Custom styles here
                "& .MuiOutlinedInput-root": {
                  borderRadius: "20px",
                  border: "1px solid #7A603A",
                  color: "#fff",
                },
                "& .MuiInputBase-input": {
                  color: "white",
                },
              }}
            />
            <div className="select-opt-section">
              {SupportedBlockchains.map((option, index) => (
                <div
                  className="token-lists"
                  key={index}
                  onClick={() => handleSelectedChain(option)}
                >
                  <div className="token-icon">
                    <ListItemIcon style={{ marginRight: "8px" }}>
                      <img
                        alt={option.chainName}
                        className=""
                        style={{ width: "38px", height: "38px" }}
                        src={`../../../Assets/chains/` + `${option.logo}`}
                      />
                    </ListItemIcon>
                  </div>
                  <div className="token-label">
                    {/*<h5>{option.tokenSymbol}</h5>*/}
                    <small>{option.chainName}</small>
                  </div>
                  <div className="right-icon">
                    <img
                      src={TokenSelectIcon}
                      alt="Right Icon"
                      style={{ width: "26px", height: "26px" }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default SelectChain;

import React, { useState, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ListItemIcon,
  CircularProgress,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CircleIcon from "@mui/icons-material/Circle";
import Slide from "@mui/material/Slide";
import Backicon from "../../assets/images/back.svg";
import SearchIcon from "@mui/icons-material/Search";
import TokenSelectIcon from "../../assets/images/token-sel-icon.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelectToken = ({ supportedTokens, selectedToken, setSelectedToken }) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  //   const [selectedToken, setSelectedToken] = useState("");

  const handleOpenDialog = () => {
    setOpen(true);
    setSearchValue("");
  };

  const handleTokenSelect = (option) => {
    setSelectedToken(option);
    setOpen(false);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  return (
    <div className="select-dialog-custom">
      <div>
        <Button
          className="select-input-style"
          onClick={handleOpenDialog}
          variant="outlined"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="icon-container">
            <img
              alt="token-icon"
              style={{
                marginRight: "8px",
                width: "28px",
                height: "28px",
              }}
              src={"../../../Assets/tokens/" + `${selectedToken?.tokenIcon}`}
            />
            {/* <CircleIcon
                                style={{ marginRight: "8px", color: "rgb(90, 98, 112)" }}
                            /> */}
            {selectedToken?.tokenName}
          </div>
          <ArrowDropDownIcon />
        </Button>

        <Dialog
          className="dialog-style"
          open={open}
          onClose={handleCloseDialog}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            <div className="header-select d-flex">
              <img
                onClick={handleCloseDialog}
                src={Backicon}
                alt="SVG Icon"
                style={{ width: "24px", height: "24px", marginRight: "8px" }}
              />
              <h2> Select a Token</h2>
            </div>
          </DialogTitle>

          <DialogContent className="token-select-dialog">
            <TextField
              placeholder="Search by name or symbol"
              inputProps={{
                autoComplete: "off",
              }}
              value={searchValue}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <ListItemIcon className="search-placeholder">
                    <SearchIcon className="text-white" />
                  </ListItemIcon>
                ),
              }}
              sx={{
                // Custom styles here
                "& .MuiOutlinedInput-root": {
                  borderRadius: "20px",
                  border: "1px solid #7A603A",
                  color: "#fff",
                },
                "& .MuiInputBase-input": {
                  color: "white",
                },
              }}
            />
            <div className="select-opt-section">
              {supportedTokens?.map((option, index) => (
                <div className="token-lists" key={index}>
                  <div className="token-icon">
                    <ListItemIcon style={{ marginRight: "8px" }}>
                      <img
                        alt={option?.tokenSymbol}
                        className=""
                        style={{ width: "38px", height: "38px" }}
                        src={"../../../Assets/tokens/" + `${option?.tokenIcon}`}
                      />
                    </ListItemIcon>
                  </div>
                  <div
                    className="token-label"
                    onClick={() => handleTokenSelect(option)}
                  >
                    <h5>{option?.tokenSymbol}</h5>
                    <small>{option?.tokenName}</small>
                  </div>
                  <div className="right-icon">
                    <img
                      src={TokenSelectIcon}
                      alt="Right Icon"
                      style={{ width: "26px", height: "26px" }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default SelectToken;

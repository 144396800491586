import React from 'react'
import Back from "../../assets/images/back.svg"
import "./Swap.scss"
import Input from './Input';
import CustomButton from './CustomButton';

const SettingBox = (props) => {

    const { onBack, customSlippage } = props;
    return (
        <div className="confirm-dialog-card slippage-section">
            <div className="">
                <div className="header-select d-flex">
                    <img src={Back} alt="Back" onClick={onBack} className="cursor-pointer close-icon" />
                    <h2> Settings</h2>
                </div>
                <div className="dialog-content-body pb-lg-0 mt-3 d-flex align-items-center justify-content-end">
                    <Input Label="Slippage Tolerance" Placeholder="0.0" />
                    <CustomButton Label="Auto"  />
                </div>
            </div>
        </div>
    )
}

export default SettingBox


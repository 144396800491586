import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React from 'react'
import Tooltip from '../Tooltips/Tooltip'
import MarketIcon from "../../assets/images/marketIcon.svg"

const Accordian = () => {
    return (
        <div className='outer-accordian '>
            <Accordion className='custom-accordion bg-transparent my-3'>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Tooltip text="Tooltip content goes here">
                        Best price for this trade based on the current market conditions
                    </Tooltip> <p className='mb-0 ms-1'>1 FXS = 5.994938 MATIC <span>(~$6.2527)</span></p>
                </AccordionSummary>
                <AccordionDetails>
                    <ul className='ps-0 mb-0'>
                        <li className='d-flex align-items-center justify-content-between'>
                            <p>Est. Received  <Tooltip text="Tooltip content goes here">
                                Best price for this trade based on the current market conditions
                            </Tooltip> </p>
                            <p>203.827882778</p>
                        </li>
                        <li className='d-flex align-items-center justify-content-between'>
                            <p>Min. Received  <Tooltip text="Tooltip content goes here">
                                Best price for this trade based on the current market conditions
                            </Tooltip> </p>
                            <p>199.751325122</p>
                        </li>
                        <li className='d-flex align-items-center justify-content-between'>
                            <p>Fees  <Tooltip text="Tooltip content goes here">
                                Best price for this trade based on the current market conditions
                            </Tooltip> </p>
                            <p>203.827882778</p>
                        </li>
                        <li className='d-flex align-items-center justify-content-between'>
                            <p>Routing  <Tooltip text="Tooltip content goes here">
                                Best price for this trade based on the current market conditions
                            </Tooltip> </p>
                            <p>FXS > MATIC</p>
                        </li>
                    </ul>
                </AccordionDetails>
            </Accordion>
            <Accordion className='custom-accordion bg-transparent my-3'>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <img src={MarketIcon} alt="MarketIcon" /><p className='mb-0 ms-1'>Market Offers</p>
                </AccordionSummary>
                <AccordionDetails>
                    <ul className='ps-0 mb-0'>
                        <li className='d-flex align-items-center justify-content-between'>
                            <div className='left-col d-flex align-items-center'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle id="Ellipse 10" cx="8" cy="8" r="8" fill="url(#paint0_linear_23906_177070)"></circle><path id="Combined Shape" d="M12.1779 11.2412L8.33066 4.60891L4.48326 11.2412L12.1779 11.2412ZM5.62703 7.7178L7.39474 4.60891H3.85937L5.62703 7.7178ZM10.6067 10.3633H6.0546L8.33066 6.43953L10.6067 10.3633Z" fill="white"></path><defs><linearGradient id="paint0_linear_23906_177070" x1="3.87822" y1="17.6112" x2="13.9953" y2="-2.60422" gradientUnits="userSpaceOnUse"><stop stop-color="#1359ED"></stop><stop offset="0.499698" stop-color="#588EFF"></stop><stop offset="1" stop-color="#558CFF"></stop></linearGradient></defs></svg>
                                <p className='mb-0 ms-1'>ParaSwap</p>
                            </div>
                            <div className='right-col'>
                                <p>1 ETH = 2,928.4534 DAI</p>
                                <span className='d-block text-end align-items-center'><svg width="16" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className='me-1'><path d="M9.5 3C9.5 2.72384 9.27616 2.5 9 2.5H4C3.72384 2.5 3.5 2.72384 3.5 3V7C3.5 7.27616 3.72384 7.5 4 7.5H9C9.27616 7.5 9.5 7.27616 9.5 7V3ZM8.5 6.5H4.5V3.5H8.5V6.5Z" fill="current"></path><path d="M15.2356 3.54541L13.2415 2.54541C12.994 2.42138 12.6972 2.52222 12.5736 2.76903C12.4501 3.01609 12.5517 3.31641 12.7987 3.43994L13.5582 3.81928C13.5378 3.87366 13.5116 3.93116 13.5116 3.99269C13.5116 4.64369 14 5.1935 14.5 5.40053V11.4927C14.5 11.7683 14.2756 11.9927 14 11.9927C13.7244 11.9927 13.5 11.7683 13.5 11.4927V7.49269C13.5 6.28547 12.5 5.27553 11.5 5.04322V1.99269C11.5 0.889906 10.6267 0 9.52388 0H3.52387C2.42109 0 1.5 0.889906 1.5 1.99269V13.1836L0.776375 13.5454C0.606937 13.6301 0.5 13.8032 0.5 13.9927V15.4927C0.5 15.7688 0.74775 16 1.02387 16H12.0239C12.3 16 12.5 15.7688 12.5 15.4927V13.9927C12.5 13.8032 12.3931 13.6301 12.2236 13.5454L11.5 13.1836V6.08484C12 6.29187 12.5 6.84169 12.5 7.49269V11.4927C12.5 12.3198 13.1728 12.9927 14 12.9927C14.8272 12.9927 15.5 12.3198 15.5 11.4927V3.99269C15.5 3.80322 15.405 3.63013 15.2356 3.54541ZM11.5 15H1.5V14.3018L2.22362 13.9399C2.39306 13.8552 2.5 13.6821 2.5 13.4927V1.99266C2.5 1.44116 2.97234 1 3.52387 1H9.52388C10.0754 1 10.5 1.44116 10.5 1.99269V13.4927C10.5 13.6822 10.6069 13.8553 10.7764 13.94L11.5 14.3018V15Z" fill="current"></path></svg>
                                    $7.34</span>

                            </div>
                        </li>
                        <li className='d-flex align-items-center justify-content-between'>
                            <div className='left-col d-flex align-items-center'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle id="Ellipse 10" cx="8" cy="8" r="8" fill="url(#paint0_linear_23906_177070)"></circle><path id="Combined Shape" d="M12.1779 11.2412L8.33066 4.60891L4.48326 11.2412L12.1779 11.2412ZM5.62703 7.7178L7.39474 4.60891H3.85937L5.62703 7.7178ZM10.6067 10.3633H6.0546L8.33066 6.43953L10.6067 10.3633Z" fill="white"></path><defs><linearGradient id="paint0_linear_23906_177070" x1="3.87822" y1="17.6112" x2="13.9953" y2="-2.60422" gradientUnits="userSpaceOnUse"><stop stop-color="#1359ED"></stop><stop offset="0.499698" stop-color="#588EFF"></stop><stop offset="1" stop-color="#558CFF"></stop></linearGradient></defs></svg>
                                <p className='mb-0 ms-1'>AugustusRFQ</p>
                            </div>
                            <div className='right-col'>
                                <p>1 ETH = 2,928.4534 DAI</p>
                                <span className='d-block text-end align-items-center'><svg width="16" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className='me-1'><path d="M9.5 3C9.5 2.72384 9.27616 2.5 9 2.5H4C3.72384 2.5 3.5 2.72384 3.5 3V7C3.5 7.27616 3.72384 7.5 4 7.5H9C9.27616 7.5 9.5 7.27616 9.5 7V3ZM8.5 6.5H4.5V3.5H8.5V6.5Z" fill="current"></path><path d="M15.2356 3.54541L13.2415 2.54541C12.994 2.42138 12.6972 2.52222 12.5736 2.76903C12.4501 3.01609 12.5517 3.31641 12.7987 3.43994L13.5582 3.81928C13.5378 3.87366 13.5116 3.93116 13.5116 3.99269C13.5116 4.64369 14 5.1935 14.5 5.40053V11.4927C14.5 11.7683 14.2756 11.9927 14 11.9927C13.7244 11.9927 13.5 11.7683 13.5 11.4927V7.49269C13.5 6.28547 12.5 5.27553 11.5 5.04322V1.99269C11.5 0.889906 10.6267 0 9.52388 0H3.52387C2.42109 0 1.5 0.889906 1.5 1.99269V13.1836L0.776375 13.5454C0.606937 13.6301 0.5 13.8032 0.5 13.9927V15.4927C0.5 15.7688 0.74775 16 1.02387 16H12.0239C12.3 16 12.5 15.7688 12.5 15.4927V13.9927C12.5 13.8032 12.3931 13.6301 12.2236 13.5454L11.5 13.1836V6.08484C12 6.29187 12.5 6.84169 12.5 7.49269V11.4927C12.5 12.3198 13.1728 12.9927 14 12.9927C14.8272 12.9927 15.5 12.3198 15.5 11.4927V3.99269C15.5 3.80322 15.405 3.63013 15.2356 3.54541ZM11.5 15H1.5V14.3018L2.22362 13.9399C2.39306 13.8552 2.5 13.6821 2.5 13.4927V1.99266C2.5 1.44116 2.97234 1 3.52387 1H9.52388C10.0754 1 10.5 1.44116 10.5 1.99269V13.4927C10.5 13.6822 10.6069 13.8553 10.7764 13.94L11.5 14.3018V15Z" fill="current"></path></svg>
                                    $7.34</span>

                            </div>
                        </li>
                    </ul>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default Accordian
import React from 'react'

const Input = ({ Placeholder, Label }) => {
    return (
        <div className='custom-input-container'>
            <label className='d-block'>{Label}</label>
            <input type="number" className='custom-input' placeholder={Placeholder} />
        </div>
    )
}

export default Input
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Header from "../../components/Header/Header";
import "./homepage.scss";
import SettingIcon from "../../assets/images/settingIcon.svg";
import SettingBox from "../../components/Swap/SettingBox";
import RefreshIcon from "../../assets/images/refresh.svg";
import SelectToken from "../../components/Swap/SelectToken";
import SelectChain from "../../components/Swap/SelectChain";
import Input from "../../components/Swap/Input";
import BalanceInput from "../../components/Swap/BalanceInput";
import AmountReceive from "../../components/Swap/AmountReceive";
import Switch from "../../assets/images/switch.svg";
import CustomButton from "../../components/Swap/CustomButton";
import Accordian from "../../components/Swap/Accordian";

export const HomePage = () => {
  const onBack = () => {
    setShowSlippage(false);
  };

  const handleOpenSlippage = () => {
    setShowSlippage(true);
  };

  const SupportedBlockchains = [
    { chainName: "Polygon", logo: "polygon-logo.svg", chainId: "137" },
    { chainName: "Bitcoin", logo: "bitcoin-logo.svg", chainId: "" }, // wip: add chain ID
    { chainName: "Zeta", logo: "zeta-logo.svg", chainId: "2" }, // wip: cross verify all chain ID's
    { chainName: "Binance", logo: "binance-logo.svg", chainId: "56" },
    { chainName: "Ethereum", logo: "ethereum-logo.svg", chainId: "1" },
  ];
  // const supportedTokensAsPerChain = [
  //   {
  //     id: 1,
  //     tokenName: "ETH",
  //     tokenSymbol: "ETH",
  //     tokenIcon: "ethereum-logo.svg",
  //   },
  //   {
  //     id: 2,
  //     tokenName: "USDT",
  //     tokenSymbol: "AvL",
  //     tokenIcon: "avalanche.svg",
  //   },
  //   {
  //     id: 3,
  //     tokenName: "USDT",
  //     tokenSymbol: "AvL",
  //     tokenIcon: "avalanche.svg",
  //   },
  // ];

  const bitcoinTokens = [
    {
      id: 1,
      tokenName: "BTC",
      tokenSymbol: "BTC",
      tokenIcon: "bitcoin-logo.svg",
    },
  ];

  const binanceTokens = [
    {
      id: 1,
      tokenName: "BNB",
      tokenSymbol: "BNB",
      tokenIcon: "binance-logo.svg",
    },
    {
      id: 2,
      tokenName: "USDC",
      tokenSymbol: "USDC",
      tokenIcon: "usdc-logo.svg",
    },
    {
      id: 3,
      tokenName: "ZETA",
      tokenSymbol: "ZETA",
      tokenIcon: "zeta-logo.svg",
    },
    {
      id: 4,
      tokenName: "BTC",
      tokenSymbol: "BTC",
      tokenIcon: "bitcoin-logo.svg",
    },
  ];

  const ethereumTokens = [
    {
      id: 1,
      tokenName: "ETH",
      tokenSymbol: "ETH",
      tokenIcon: "ethereum-logo.svg",
    },
    {
      id: 2,
      tokenName: "USDC",
      tokenSymbol: "USDC",
      tokenIcon: "usdc-logo.svg",
    },
  ];

  const polygonTokens = [
    {
      id: 1,
      tokenName: "Matic",
      tokenSymbol: "MATIC",
      tokenIcon: "polygon-logo.svg",
    },
    {
      id: 2,
      tokenName: "USDC",
      tokenSymbol: "USDC",
      tokenIcon: "usdc-logo.svg",
    },
    {
      id: 3,
      tokenName: "ZETA",
      tokenSymbol: "ZETA",
      tokenIcon: "zeta-logo.svg",
    },
  ];

  const zetaTokens = [
    {
      id: 1,
      tokenName: "ETH",
      tokenSymbol: "ETH",
      tokenIcon: "ethereum-logo.svg",
    },
    {
      id: 2,
      tokenName: "USDC",
      tokenSymbol: "USDC",
      tokenIcon: "usdc-logo.svg",
    },
    {
      id: 3,
      tokenName: "Matic",
      tokenSymbol: "MATIC",
      tokenIcon: "polygon-logo.svg",
    },
    {
      id: 4,
      tokenName: "BTC",
      tokenSymbol: "BTC",
      tokenIcon: "bitcoin-logo.svg",
    },
    {
      id: 5,
      tokenName: "BNB",
      tokenSymbol: "BNB",
      tokenIcon: "binance-logo.svg",
    },
    {
      id: 6,
      tokenName: "ZETA",
      tokenSymbol: "ZETA",
      tokenIcon: "zeta-logo.svg",
    },
  ];

  const [showSlippage, setShowSlippage] = useState(false);
  const [selectedFromChainForDropdown, setSelectedFromChainForDropdown] =
    useState();

  const [selectedToChainForDropdown, setSelectedToChainForDropdown] =
    useState();

  const [supportedFromTokensAsPerChain, setSupportedFromTokensAsPerChain] =
    useState();
  const [supportedToTokensAsPerChain, setSupportedToTokensAsPerChain] =
    useState();

  const [selectedFromToken, setSelectedFromToken] = useState("");
  const [selectedToToken, setSelectedToToken] = useState("");

  useEffect(() => {
    setSelectedFromToken("");
    if (selectedFromChainForDropdown?.chainName === "Polygon") {
      setSupportedFromTokensAsPerChain(polygonTokens);
    } else if (selectedFromChainForDropdown?.chainName === "Bitcoin") {
      setSupportedFromTokensAsPerChain(bitcoinTokens);
    } else if (selectedFromChainForDropdown?.chainName === "Zeta") {
      setSupportedFromTokensAsPerChain(zetaTokens);
    } else if (selectedFromChainForDropdown?.chainName === "Binance") {
      setSupportedFromTokensAsPerChain(binanceTokens);
    } else if (selectedFromChainForDropdown?.chainName === "Ethereum") {
      setSupportedFromTokensAsPerChain(ethereumTokens);
    }
  }, [selectedFromChainForDropdown]);

  useEffect(() => {
    setSelectedToToken("");
    if (selectedToChainForDropdown?.chainName === "Polygon") {
      setSupportedToTokensAsPerChain(polygonTokens);
    } else if (selectedToChainForDropdown?.chainName === "Bitcoin") {
      setSupportedToTokensAsPerChain(bitcoinTokens);
    } else if (selectedToChainForDropdown?.chainName === "Zeta") {
      setSupportedToTokensAsPerChain(zetaTokens);
    } else if (selectedToChainForDropdown?.chainName === "Binance") {
      setSupportedToTokensAsPerChain(binanceTokens);
    } else if (selectedToChainForDropdown?.chainName === "Ethereum") {
      setSupportedToTokensAsPerChain(ethereumTokens);
    }
  }, [selectedToChainForDropdown]);

  return (
    <>
      <Header />
      <section className="bg-section d-flex align-items-center justify-content-center">
        <div className="overlap-group mx-auto w-100 p-2">
          <Row className="box-header ">
            <div className="box-header justify-content-between d-flex align-items-center">
              <h1>Swap</h1>
              <div class="dropdown custom-dropdown-slippage d-flex align-items-center">
                <button className="border-0 p-0 outline-0 d-flex align-items-center justify-content-center">
                  <img
                    className="box-header-icon"
                    src={RefreshIcon}
                    alt="Analytics Logo"
                  />
                </button>
                <button
                  className="border-0 p-0 outline-0 d-flex align-items-center justify-content-center"
                  type="button"
                  onClick={handleOpenSlippage}
                >
                  <img
                    className="box-header-icon"
                    src={SettingIcon}
                    alt="Analytics Logo"
                  />
                </button>
                {showSlippage && (
                  <div className="custom-dropdown-setting">
                    <SettingBox onBack={onBack} />
                  </div>
                )}
              </div>
            </div>
            <div className="box-body position-relative">
              <button className="switch-btn d-flex align-items-center justify-content-center position-absolute">
                <img src={Switch} />
              </button>
              <div className="amount-div p-2 mt-3">
                <div className="d-flex align-items-end justify-content-between mb-2">
                  <Input Label="You Pay" Placeholder="0.0" />
                  <SelectChain
                    SupportedBlockchains={SupportedBlockchains}
                    setSelectedFromChainForDropdown={
                      setSelectedFromChainForDropdown
                    }
                    selectedFromChainForDropdown={selectedFromChainForDropdown}
                    isItFromToken={true}
                  />
                  <SelectToken
                    supportedTokens={supportedFromTokensAsPerChain}
                    selectedToken={selectedFromToken}
                    setSelectedToken={setSelectedFromToken}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <AmountReceive />
                  <BalanceInput />
                </div>
              </div>
              <div className="amount-div p-2 mt-2">
                <div className="d-flex align-items-end justify-content-between mb-2">
                  <Input Label="You Receive" Placeholder="0.0" />
                  <SelectChain
                    SupportedBlockchains={SupportedBlockchains}
                    setSelectedToChainForDropdown={
                      setSelectedToChainForDropdown
                    }
                    selectedToChainForDropdown={selectedToChainForDropdown}
                    isItFromToken={false}
                  />
                  <SelectToken
                    supportedTokens={supportedToTokensAsPerChain}
                    selectedToken={selectedToToken}
                    setSelectedToken={setSelectedToToken}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <AmountReceive />
                  <BalanceInput />
                </div>
              </div>
            </div>
            <Accordian />
          </Row>
          <div className="box-footer">
            <CustomButton
              Label="Insufficient FXS balance"
              classCustom="ghost"
            ></CustomButton>
          </div>
        </div>
      </section>
    </>
  );
};

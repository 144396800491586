import React from 'react'

const AmountReceive = () => {
    return (
        <div>
            <p className='mb-0'>~<span>$81.94</span></p>
        </div>
    )
}

export default AmountReceive
import React from 'react'

const BalanceInput = () => {
    return (
        <div>
            <p className='balance-text mb-0'>Balance: <span>0</span> <button>Max</button></p>
        </div>
    )
}

export default BalanceInput
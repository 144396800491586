import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/images/logo-icon.svg";
import "./Header.scss";
import Chaindropdown from "./Chaindropdown";
import ConnectWalletButton from "./ConnectWalletButton";
// import { NavLink } from 'react-bootstrap';

const Header = ({ setSelectedChainId, setIsCntToNotSupportedChain }) => {
  const [scroll, setScroll] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(0);

  const handleMenuItemClick = (index) => {
    setActiveMenuItem(index);
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  return (
    <header className={scroll ? "main-header scrolled" : "main-header"}>
      <Navbar collapseOnSelect expand="lg" className="p-0">
        <Container fluid>
          <div className="custom-nav py-md-2 py-3 w-100 d-md-flex align-items-center text-start">
            <Navbar.Brand href="#home">
              <img src={Logo} alt="Logo" className="logo-img" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <div className="align-items-center d-lg-flex inner-custom-nav order-md-4"></div>

            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-between mt-md-0 mt-4"
            >
              <Nav className="mx-auto my-0">
                <li>
                  <NavLink
                    to="/"
                    activeClassName="active"
                    onClick={() => handleMenuItemClick(0)}
                  >
                    Swap
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/ido"
                    activeClassName="active"
                    onClick={() => handleMenuItemClick(1)}
                  >
                    IDO
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/"
                    activeClassName="active"
                    onClick={() => handleMenuItemClick(0)}
                  >
                    Listings
                  </NavLink>
                </li>
              </Nav>
            </Navbar.Collapse>
          </div>
          <div className="right d-flex align-items-center">
            {/*<Chaindropdown />*/}
            <ConnectWalletButton />
          </div>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;

// components/ConnectWalletButton.js
import React from "react";
import { Button } from "@mui/material";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const ConnectWalletButton = () => {
  return (
    // <Button color="inherit" onClick={() => alert("Connect Wallet")} className="custom-button">
    //   Connect Wallet
    // </Button>
    <div
      style={{
        // display: "flex",
        // justifyContent: "flex-end",
        // padding: 12,
        width: "300px",
        color: "inherit",
        className: "custom-button",
      }}
    >
      <ConnectButton
        showBalance={false}
        // chainStatus="none"
      />
    </div>
  );
};

export default ConnectWalletButton;
